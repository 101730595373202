<template>
  <v-form @submit.prevent="$emit('save', password)">
    <v-card>
      <v-card-title>
        {{ $t('new_password') }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="password"
              :error-messages="errors.pass"
              :append-icon="isPasswordHidden ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isPasswordHidden ? 'password' : 'text'"
              :label="formMixin_getRequiredFieldLabel($t('password'))"
              @click:append="isPasswordHidden = !isPasswordHidden"
              @input="formMixin_clearErrors('pass')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="primary" @click="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn :disabled="disabled" :loading="disabled" color="primary" text type="submit">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';

export default {
  name: 'PasswordForm',

  mixins: [formMixin],

  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      password: '',
      isPasswordHidden: true,
    };
  },
};
</script>

<style scoped></style>
